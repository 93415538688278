import React, { PureComponent, createRef } from "react";
import {
  Container,
  Button,
  Grid,
  Image,
  Icon,
  Modal,
  Accordion,
  Checkbox,
  List,
} from "semantic-ui-react";
import {
  isEmpty,
  map,
  filter,
  reduce,
  difference,
  includes,
  get,
  findIndex,
  flatten,
  isEqual,
} from "lodash";
import html2pdf from "html2pdf-jspdf2";
import { Link } from "react-router-dom";
import moment from "moment";
import { PDFReader } from "reactjs-pdf-reader";

//Redux
import { connect } from "react-redux";
import { payLiens } from "../../redux/actions/user";
import { addCart } from "../../redux/actions/cart";
import { getDownloadedUrl } from "../../redux/actions/downloads";

//Components
import Header from "../../components/Header";
// import ShareReport from "../../components/ShareReport";
import Footer from "../../components/Footer";
import Text from "../../components/Text";
import ReportBox from "../../components/ReportBox";
import BillBox from "../../components/BillBox";
import CityHeader from "../../components/CityHeader";
import Box from "../../components/Box";

//Constants
import { currencyFormat, getDateLabel, dateDiff } from "../../constants/Utils";
import { calculateInterest } from "../../utils/business";

//Assets
import PoweredBy from "../../assets/poweredBy.png";

//Styles
import styles from "./styles.module.css";

import Api from "../../api";

class Report extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      totalAmount: 0,
      seal: null,
      shareVisible: false,
      sharedEmailsActive: false,
      emails: null,
      attachments: [],
      pdfWidth: 500,
    };

    this.printArea = createRef();
    this.pdfContainer = createRef();
  }

  fetchSharedUserEmail = async () => {
    try {
      const { id, userId } = this.props.currentReport;
      const response = await Api.post("/getMailShare", {
        reportId: id,
        userId,
      });

      this.setState({ emails: response.data.data[0].shareEmail });
    } catch (error) {}
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    const { currentReport, dispatch } = this.props;
    const { liens, bills } = currentReport;

    await this.fetchSharedUserEmail();

    if (!isEmpty(currentReport)) {
      const lienAttachments = map(currentReport.liens, (item) => {
        return item.attachments;
      });
      const billAttachments = map(currentReport.bills, (item) => {
        return item.attachments;
      });
      const newAttachments = [...flatten(lienAttachments), ...flatten(billAttachments)];
      const attachments = await Promise.all(
        map(newAttachments, async (item) => {
          const [url] = await dispatch(getDownloadedUrl(item));
          const isImage = String(item).match(/.(jpg|jpeg|png)$/i);
          return { url, isImage };
        })
      );

      let totalAmount = 0;

      if (!isEmpty(liens)) {
        const amountArray = map(liens, (item) => {
          const hasComplied = item.dateComplied ? true : false;
          const endDate = hasComplied ? moment(item.dateComplied) : moment();
          const diffDays = dateDiff(moment(item.dateOfFineBegan), endDate) + 1;
          const dailyFine = parseFloat(item.originalFine) * diffDays;
          const otherFees = parseFloat(item.adminFee) + parseFloat(item.otherFee);
          const amountDue = dailyFine + otherFees;
          return amountDue;
        });
        const totalLiens = reduce(
          amountArray,
          (total, item) => {
            return total + item;
          },
          0
        );
        totalAmount += totalLiens;
      }
      if (!isEmpty(bills)) {
        const amountArray = map(bills, (item) => {
          return item.currentBalance;
        });
        const totalBills = reduce(
          amountArray,
          (total, item) => {
            return total + item;
          },
          0
        );
        totalAmount += totalBills;
      }
      this.setState({ totalAmount: totalAmount.toFixed(2), attachments });
    }
  };

  componentDidUpdate = async (prevProps) => {
    const { currentReport, dispatch } = this.props;
    if (!isEmpty(currentReport)) {
      if (this.pdfContainer.current) {
        const resizeObserver = new ResizeObserver((event) => {
          this.setState({
            pdfWidth: event[0]?.contentBoxSize[0]?.inlineSize,
          });
        });
        resizeObserver.observe(document.getElementById("pdfDiv0"));
      }
      if (!isEqual(prevProps.currentReport, currentReport)) {
        const lienAttachments = map(currentReport.liens, (item) => {
          return item.attachments;
        });
        const billAttachments = map(currentReport.bills, (item) => {
          return item.attachments;
        });
        const newAttachments = [...flatten(lienAttachments), ...flatten(billAttachments)];
        const attachments = await Promise.all(
          map(newAttachments, async (item) => {
            const [url] = await dispatch(getDownloadedUrl(item));
            const isImage = String(item).match(/.(jpg|jpeg|png)$/i);
            return { url, isImage };
          })
        );

        this.setState({ attachments });
      }
    }
  };

  handleMitigationAmountRequest = async () => {
    const { dispatch, currentReport } = this.props;
    const amount = this.state.totalAmount;
    const data = {
      description: `${currentReport.user.name} paid ${amount} `,
      reports: currentReport,
      total: this.state.totalAmount,
    };

    await dispatch(payLiens(data));
  };

  handlePrint = () => {
    if (!this.printArea.current) return;
    let options = {
      margin: [10, 10, 0, 10],
      filename: `${Date.now()}.pdf`,
      image: { type: "jpeg", quality: 0.8 },
      html2canvas: { scale: 1.2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all"] },
    };
    html2pdf()
      .set(options)
      .from(this.printArea.current)
      .toPdf()
      .get("pdf")
      .then((pdfObj) => {
        window.open(pdfObj.output("bloburl"), "Print Report for Order #", "menubar=no");
      });
  };

  renderBoxes = (lienTypes) => {
    if (isEmpty(lienTypes)) return null;
    const { currentReport } = this.props;
    const boxes = map(lienTypes, (item, index) => {
      let items = [];
      const isBill = includes(["Active Bills", "Closed Bills", "Unbilled receivables"], item.type);
      items = isBill
        ? filter(currentReport.bills, { type: item.type })
        : filter(currentReport.liens, { type: item.type });
      const isGoodStanding = isEmpty(items);
      if (isBill) {
        return (
          <React.Fragment key={index}>
            <BillBox
              title={item.type}
              first={index === 0}
              isGoodStanding={isGoodStanding}
              items={items}
              asOf={currentReport.endedDate}
              exclude={item.exclude}
            />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={index}>
          <ReportBox
            title={item.type}
            first={index === 0}
            canMitigate={get(currentReport, "data.settings.canMitigate", true)}
            isGoodStanding={isGoodStanding}
            items={items}
            asOf={currentReport.endedDate}
            exclude={item.exclude}
          />
        </React.Fragment>
      );
    });
    return boxes;
  };

  canRequetMitigation = (liens) => {
    const validTypes = liens.filter((lien) => {
      return calculateInterest(lien) !== 0;
    });
    return validTypes.length !== 0;
  };

  renderDepartments = (object) => {
    if (isEmpty(object.data)) return null;
    const { liens } = this.props.currentReport;
    const cityDepartments = object.data.intercitydepartments;
    if (isEmpty(cityDepartments)) return null;
    const departments = map(cityDepartments, (item, index) => {
      return (
        <Box
          key={index}
          title={item.department.name}
          withVerified={true}
          verified={item.department.verified}>
          {this.renderBoxes(item.department.lienTypes)}
          {item.department.name === "Code Violation Department" &&
            this.canRequetMitigation(liens) && (
              <div className={styles.test}>
                <Button
                  size="small"
                  content="Request Mitigation"
                  as={Link}
                  to="/request-mitigation"
                />
              </div>
            )}
        </Box>
      );
    });
    return departments;
  };

  toogleSharePopup = () => {
    this.setState({ shareVisible: !this.state.shareVisible });
  };

  handleShareReport = async (emails) => {
    const reportId = this.props.currentReport.id;
    // this.props.dispatch(share(reportId, emails));
    const uniqueEmails = difference(emails, this.state.emails);
    if (!isEmpty(uniqueEmails)) {
      try {
        await Api.put(`/sharemail/${reportId}`, {
          email: uniqueEmails,
        });
        this.fetchSharedUserEmail();
      } catch (error) {
        console.log(error);
      }
    }
  };

  getLiensPaymentDetails = (obj) => {
    const { currentReport, user } = this.props;
    const { id, propertyId, cityId } = currentReport;
    console.log(obj);
    return {
      id,
      userId: user.id,
      propertyId,
      cityId,
      description: "",
      liens: get(obj, "liens"),
      type: "Liens Case Payments",
      amount: obj.totalAmount,
    };
  };

  isAlreadyInCart = () => {
    const { cartList, currentReport } = this.props;
    if (isEmpty(cartList)) return;
    const cartListProperties = cartList.map((property) => property.propertyId);
    const exist = includes(cartListProperties, currentReport.propertyId);
    return exist;
  };

  // handlePayNow = async (obj) => {
  //   const body = this.getLiensPaymentDetails(obj);

  //   try {
  //     const response = await Api.post("/paymentLiensCase", body);
  //     const { data: payPalUrl } = response.data;
  //     window.open(payPalUrl, "_blank");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  createOrder = async (obj) => {
    const { currentReport, user } = this.props;
    const { cityId, propertyId } = currentReport;
    try {
      let resp = await Api.post("/createOrder", {
        cart: [
          {
            type: "lienPayment",
            userId: user.id,
            cityId,
            propertyId,
            dataField: this.getLiensPaymentDetails(obj),
            amount: obj.totalAmount,
          },
        ],
      });
      const { links } = resp.data.data.order;
      const index = findIndex(links, { rel: "approve" });
      const url = links[index].href;
      window.open(url, "_blank");
    } catch (error) {
      console.log(error.response);
    }
  };

  handleAddToCart = async (obj) => {
    if (isEmpty(obj)) return;

    const { currentReport, dispatch, user } = this.props;
    const { cityId } = currentReport;
    const data = {
      type: "lienPayment",
      userId: user.id,
      cityId,
      propertyId: currentReport.propertyId,
      dataField: this.getLiensPaymentDetails(obj),
      amount: obj.totalAmount,
    };
    await dispatch(addCart(data));
  };

  renderSupportingDocs = (attachments) => {
    if (isEmpty(attachments)) return null;
    const newAttachments = map(attachments, (item, index) => {
      if (item.isImage) {
        return (
          <div
            id={`pdfDiv${index}`}
            className={styles.imageContainer}
            key={index}
            ref={this.pdfContainer}>
            <Image fluid src={item.url} />
          </div>
        );
      } else {
        return (
          <div
            id={`pdfDiv${index}`}
            className={styles.imageContainer}
            key={index}
            ref={this.pdfContainer}>
            <PDFReader url={item.url} showAllPage width={this.state.pdfWidth} />
          </div>
        );
      }
    });
    return newAttachments;
  };

  render() {
    const { currentReport } = this.props;
    const { totalAmount, attachments } = this.state;
    const cityName = get(currentReport, "data.name", "");
    return (
      <div className={styles.screen}>
        <Header />
        <div className={styles.body}>
          <Container style={{ backgroundColor: "#fff", padding: "2em" }}>
            <div className={styles.headerContainer}>
              <Text className={styles.title}>{"Lien Search Report"}</Text>
              {/* <Button.Group size="small">
              <ShareReport
                  onShare={this.handleShareReport}
                  shareVisible={this.state.shareVisible}
                  sharedWith={emails}
                  position={"bottom right"}
                  trigger={
                    <Button icon labelPosition="left" onClick={this.toogleSharePopup}>
                      <Icon name="mail" />
                      Share
                    </Button>
                  }
                /> */}

              <Button
                icon
                labelPosition="left"
                onClick={this.handlePrint}
                className={styles.printButton}>
                <Icon name="print" />
                Print
              </Button>
              {/* </Button.Group> */}
            </div>
            <div ref={this.printArea}>
              <CityHeader cityObj={!isEmpty(currentReport) ? currentReport.data : null} />
              <Box title={`Order #${currentReport.id}`}>
                <Grid stackable>
                  <Grid.Row columns={2}>
                    <Grid.Column width={7} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Date: "}</Text>
                        <Text className={styles.text}>{getDateLabel(currentReport.createdAt)}</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={9} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Requestor: "}</Text>
                        <Text className={styles.text}>{get(currentReport, "user.name", "")}</Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={7} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Date Prepared: "}</Text>
                        <Text className={styles.text}>{getDateLabel(currentReport.endedDate)}</Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={9} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Preparer: "}</Text>
                        <Text className={styles.text}>{get(currentReport, "preparer", "")}</Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={7} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Folio: "}</Text>
                        <Text className={styles.text}>
                          {get(currentReport, "property.folio", "")}
                        </Text>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={9} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Address: "}</Text>
                        <Text className={styles.addressText}>
                          {get(currentReport, "property.address", "")}
                        </Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column width={16} className={styles.gridColumn}>
                      <div className={styles.textRow}>
                        <Text className={styles.boldText}>{"Legal Description: "}</Text>
                        <Text className={styles.text}>
                          {get(currentReport, "property.legalDescription", "")}
                        </Text>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Box>
              {this.renderDepartments(!isEmpty(currentReport) ? currentReport : {})}

              {totalAmount !== 0 && (
                <div className={styles.whiteBox}>
                  <PaymentReportModal
                    label="Pay Liens"
                    report={this.props.currentReport}
                    onPay={this.createOrder}
                    onAddToCart={this.handleAddToCart}
                    // disableAddToCart={this.isAlreadyInCart()}
                  />
                </div>
              )}
              <div className={styles.saveButtonsRow}>
                <Text className={styles.disclaimerText}>
                  {`A lien search is provided as a public service by the City of ${cityName}. The City of ${cityName} makes no warranties or representations whatsoever regarding the completeness, accuracy, or timeliness of information provided and shall not be responsible for any errors or omissions, nor liable for any actions taken as a result of reliance upon incomplete or incorrect information, or any other consequence from such reliance.`}
                </Text>
              </div>
              {cityName === "Miami" && (
                <div className={styles.saveButtonsRow}>
                  <Text className={styles.disclaimerText}>
                    {`Please Note: The amounts listed on this certificate reflect all outstanding amounts for the property as of the "Service Through" date. Charges incurred for services rendered subsequent to that date are subject to nonnal collection procedures and lien if applicable. Municipal charges to the above reference property may be due. This certificate applies to water and wastewater service rendered to the above referenced property. It does not include connection charges, construction charges, and any other charges which may be owed to Miami Dade Water and Sewer Department.`}
                  </Text>
                </div>
              )}
              <Image className={styles.image} src={PoweredBy} centered />
              {this.renderSupportingDocs(attachments)}
            </div>

            {/* {totalAmount !== 0 && (
              <div className={styles.whiteBox}>
                <div className={styles.infoContainer}>
                  <Text className={styles.subTitle}>
                    {currentReport.property && currentReport.property.address
                      ? currentReport.property.address
                      : ""}
                  </Text>
                  <Text className={styles.subTitle}>
                    {currentReport.property && currentReport.property.folio
                      ? currentReport.property.folio
                      : ""}
                  </Text>
                </div>
                <div className={styles.summaryContainer}>
                  <Text className={styles.totalText}>{`Total: ${currencyFormat(
                    totalAmount
                  )}`}</Text>
                  <Button
                    className={styles.button}
                    onClick={this.handleMitigationAmountRequest}
                  >
                    {"Pay Liens Now"}
                  </Button>
                  <PaymentReportModal
                    logoSrc={seal}
                    content={
                      <LienPaymentDetails report={this.props.currentReport} />
                    }
                  />
                </div>
              </div>
            )} */}
          </Container>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps({ reports, user, cart }) {
  return {
    currentReport: reports.currentReport,
    user,
    cartList: cart.cartList,
  };
}

export default connect(mapStateToProps)(Report);

function PaymentReportModal({ report, label, onPay, onAddToCart, disableAddToCart }) {
  const [open, setOpen] = React.useState(false);
  const [liensToPay, setLiensToPay] = React.useState([]);

  const handleChange = (lienObject) => {
    const obj = filter(liensToPay, { id: lienObject.id });
    if (isEmpty(obj)) setLiensToPay((arr) => [...arr, lienObject]);
    else setLiensToPay(liensToPay.filter((e) => e.id !== lienObject.id));
  };

  const handleCancel = () => {
    setLiensToPay([]);
    setOpen(false);
  };

  const getLiensPaymentDetails = () => {
    return {
      liens: liensToPay,
      totalAmount: getTotalLiensToPayAmount(),
    };
  };

  // const handlePay = () => {
  //   const data = getLiensPaymentDetails();
  //   onPay(data);
  //   handleCancel();
  // };

  const getTotalLiensToPayAmount = () =>
    liensToPay.reduce((n, { totalAmount }) => n + totalAmount, 0);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>{label}</Button>}
      size="small">
      <Modal.Header>Lien Payment</Modal.Header>
      <Modal.Content>
        <LienPaymentDetails report={report} onChange={handleChange} />
      </Modal.Content>
      <Modal.Actions>
        <div className={styles.actions}>
          <div>
            <span className={styles.totalLabel}>Total:</span>
            <span className={styles.totalPrice}>
              &nbsp;
              {currencyFormat(getTotalLiensToPayAmount())}
            </span>
          </div>
          <div>
            <Button.Group>
              <Button
                onClick={() => onAddToCart(getLiensPaymentDetails())}
                disabled={disableAddToCart}>
                Add To Cart
              </Button>
              <Button.Or />
              <Button content="Pay Now" onClick={() => onPay(getLiensPaymentDetails())} positive />
              <Button.Or />
              <Button color="red" onClick={handleCancel}>
                Cancel
              </Button>
            </Button.Group>
          </div>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

function LienPaymentDetails({ report, onChange }) {
  const [activeIndex, setActiveIndex] = React.useState();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const departments = report.data.intercitydepartments;
  const { liens, bills } = report;

  // console.log(departments)
  // console.log(liens)

  return (
    <Accordion fluid>
      {departments &&
        departments.map((department, index) => {
          return (
            <div key={index}>
              <Accordion.Title active={true} index={index} onClick={handleClick}>
                <Icon name="dropdown" />
                {department.department.name}
              </Accordion.Title>
              <Accordion.Content active={true}>
                <List bulleted>
                  {department.department.lienTypes &&
                    department.department.lienTypes.map((lienType, index) => {
                      const liensFiltered = filter(liens, function (lien) {
                        if (lien.type === lienType.type) {
                          return lien;
                        }
                      });

                      const isActiveBills = lienType.type === "Active Bills";
                      return (
                        <div key={index}>
                          {liensFiltered.length !== 0 && (
                            <Grid columns={3} textAlign="center">
                              {liensFiltered.map((item, i) => {
                                const totalAmount = calculateInterest(item);
                                if (totalAmount > 0) {
                                  let target = item;
                                  target["totalAmount"] = totalAmount;
                                  return (
                                    <Grid.Row key={i}>
                                      <Grid.Column className={styles.col}>
                                        <Checkbox
                                          label={`${item.type}`}
                                          onChange={() => onChange(target)}
                                        />
                                      </Grid.Column>
                                      <Grid.Column className={styles.col}>
                                        {item.caseNumber}
                                      </Grid.Column>
                                      <Grid.Column className={styles.col}>
                                        {currencyFormat(totalAmount)}
                                      </Grid.Column>
                                    </Grid.Row>
                                  );
                                }
                                return null;
                              })}
                            </Grid>
                          )}

                          {isActiveBills && (
                            <Grid columns={3} textAlign="center">
                              {bills.map((item, i) => {
                                if (item.currentBalance > 0) {
                                  let target = item;
                                  target["totalAmount"] = item.currentBalance;
                                  return (
                                    <Grid.Row key={i}>
                                      <Grid.Column className={styles.col}>
                                        <Checkbox
                                          label={item.type}
                                          onChange={() => onChange(target)}
                                        />
                                      </Grid.Column>
                                      <Grid.Column className={styles.col}>
                                        {item.accountType}
                                      </Grid.Column>
                                      <Grid.Column className={styles.col}>
                                        {currencyFormat(item.currentBalance)}
                                      </Grid.Column>
                                    </Grid.Row>
                                  );
                                }
                                return null;
                              })}
                            </Grid>
                          )}
                        </div>
                      );
                    })}
                </List>
              </Accordion.Content>
            </div>
          );
        })}
    </Accordion>
  );
}
